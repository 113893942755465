import Vue from 'vue'

export default new Vue()

export const EVENTS = {
  FILE_UPLOADED: 'file-uploaded',
  LEGAL_CONDITIONS_ORDER_CREATE_ACCEPTED: 'legal-conditions-order-create-accepter',
  MODAL_CONFIRM_YES_CLICK: 'modal-confirm-yes-click',
  MODAL_CONFIRM_NO_CLICK: 'modal-confirm-no-click',
  PREVENT_STEP_NAVIGATION_CONFIRM_EVENT: 'prevent-step-navigation-confirm',
  PREVENT_STEP_BUTTON_NAVIGATION_CONFIRM_EVENT: 'prevent-step-button-navigation-confirm',
  ADD_WORKGROUP_RELATION_SENT_AND_WAIT: 'add-workgroup-relation-sent-and-wait',
  ADD_WORKGROUP_RELATION_SENT_AND_KEEP_EDIT: 'add-workgroup-relation-sent-and-keep-edit',
  ADD_SUPER_STRUCTURE: 'add-super-structure',
  START_SUPER_STRUCTURE_TEETH_SELECTION: 'start-super-structure-teeth-selection',
  BAR_COMPLETED: 'bar-completed',
  CANCEL_TEETH_SELECTION_CONFIGURATION: 'cancel-teeth-selection-configuration',
  ENABLE_WITH_SUPER_STRUCTURE_TO_SELECTION: 'enable_with_super_structure_to_selection',
  ENABLE_DESIGNER_EDIT_PRESCRIPTION: 'enable-designer-edit-prescription',
  OPEN_MODAL_ENABLE_DESIGNER_EDIT_PRESCRIPTION: 'open-modal-enable-designer-edit-prescription'
}
