// File uploads state managment

import EventBus, { EVENTS } from '~/services/eventBus'

export const state = () => ({
  uploadService: null,
  files: []
})

export const mutations = {
  ensureUploader(state, uploadService) {
    if(!state.uploadService) {
      state.uploadService = uploadService
    }
  },

  addFile(state, file) {
    state.files = [...state.files, file]
  },

  removeFile(state, file) {
    state.files = state.files.filter(({ id }) => id !== file.id)
  },

  fileReady(state, fileId) {
    state.files = state.files.map(stateFile => {
      if(stateFile.id === fileId) {
        return { ...stateFile, status: 'ready' }
      }

      return stateFile
    })
  },

  fileUploadCompleted(state, { id, signedId, directUpload }) {
    state.files = state.files.map(stateFile => {
      if(stateFile.id === id) {
        return { ...stateFile, signedId, directUpload, status: 'completed' }
      }

      return stateFile
    })
  },

  fileUploadAttached(state, fileId) {
    state.files = state.files.map(stateFile => {
      if(stateFile.id === fileId) {
        return { ...stateFile, status: 'attached' }
      }

      return stateFile
    })
  },

  fileUploadProgress(state, { id, total, loaded }) {
    state.files = state.files.map(stateFile => {
      if(stateFile.id === id) {
        const ratio = total > 0 ? Math.round(100 * loaded / total) : 0
        const status = ratio > 0 ? 'uploading' : 'ready'
        return { ...stateFile, progress: ratio, status }
      }

      return stateFile
    })
  },

  fileInError(state, fileId) {
    state.files = state.files.map(stateFile => {
      if(stateFile.id === fileId) {
        return { ...stateFile, status: 'failed' }
      }

      return stateFile
    })
  },

  fileCancelUpload(state, fileId) {
    state.files = state.files.map(stateFile => {
      if(stateFile.id === fileId) {
        return { ...stateFile, shouldCancel: true }
      }

      return stateFile
    })
  },

  clearStateByUploaderKey(state, uploaderKey) {

    state.files = state.files.filter(f =>
      f.uploaderKey !== uploaderKey || (f.shouldCancel && uploadingStatus(f))
    )

    if(uploaderKey.includes('additional_attachments')) {
      state.files = state.files.filter( f =>
        !uploaderKey.includes('additional_attachments')
      )
    }
  }
}

export const actions = {
  clearState({ commit }, uploaderKeys) {
    const uploaderKeysArr = [uploaderKeys].flat()
    uploaderKeysArr.forEach(uploaderKey => {
      commit('clearStateByUploaderKey', uploaderKey)
    })
  },

  removeAttachedFile({ state, commit }, uploaderKeys) {
    const uploaderKeysArr = [uploaderKeys].flat()
    uploaderKeysArr.forEach(uploaderKey => {
      state.files.filter(file => file.status === 'attached' && file.uploaderKey === uploaderKey)
                 .forEach(file => commit('removeFile', file))
    })
  },

  uploadFile({ commit, state }, { file, context }) {
    state.uploadService.upload(file.blob, {
      onFileUpload: (progress, directUploadRequest) => {
        const currentFileFromStore = state.files.find(({ id }) => file.id === id)
        if (currentFileFromStore.shouldCancel === true) {
          directUploadRequest.abort()
          commit('removeFile', file)
        }

        commit('fileUploadProgress', { id: file.id, loaded: progress.loaded, total: progress.total })
      }
    })
    .then(blob => {
      commit('fileUploadCompleted', { id: file.id, signedId: blob.signed_id, directUpload: blob.directUpload })
      return context.$axios.put(file.attachFileUrl, { attachment: blob.signed_id })
    })
    .then(response => {
      EventBus.$emit(EVENTS.FILE_UPLOADED, {
        key: file.uploaderKey, payload: { ...response.data, fileId: file.id }
      })
      commit('fileUploadAttached', file.id)
    })
    .catch(err => {
      context.$sentry.captureException(err)
      commit('fileInError', file.id)
    })
  },

  retryFileUpload({ commit, dispatch }, { file, context }) {
    commit('fileReady', file.id)
    dispatch('uploadFile', { file, context })
  }
}

const equalUploaderKey = uploaderKey => file => file.uploaderKey === uploaderKey

const uploadingStatus = file => ['uploading', 'completed'].includes(file.status)

export const getters = {
  uploader: (state) => state.uploadService,

  files: (state) => (uploaderKey) => state.files.filter(equalUploaderKey(uploaderKey)),

  allFiles: (state) => state.files || [],

  uploadingFiles: (state) => state.files.filter(uploadingStatus),

  failedFiles: (state) => state.files.filter(f => f.status === 'failed'),

  attachedFiles: (state) => state.files.filter(f => f.status === 'attached'),

  readyFiles: (state) => state.files.filter(f => f.status === 'ready'),

  uploadingFilesForKey: (state) => (uploaderKey) => {
    return state.files.filter(equalUploaderKey(uploaderKey))
                      .filter(uploadingStatus)
  }
}
